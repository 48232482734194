*,
*::before,
*::after {
    box-sizing: border-box;
}

.contactPage {
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    padding: 25px;
    text-align: center;
}

.infoGrid {
    margin: 0 auto;
    gap: 20px;
    width: 80%;
    height: auto;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.contactInfo {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 16px;
    margin-top: 18px;
    margin-bottom: 18px;
    text-decoration: none;
    color: #0056b3;
}

.ContactForm {
    width: 100%;
    height: auto;
    padding-top: 25px;
}

.Formheading {
    font-size: 3rem;
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    margin: 0 auto;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 16px;
}

.inputGroup {
    width: 100%;
}

textarea {
    min-height: 100px;
    resize: vertical;
}

button:hover {
    background-color: #0056b3;
}

button:disabled,
button[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    cursor: auto;
}

.invalid {
    border-color: red;
}

.agb-consent-holder {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
    align-items: flex-start;
    justify-content: space-evenly;
    display: flex;
    flex-direction: row;
    text-align: left;
}

.agb-consent-holder p {
    width: 80%;
    padding: 0;
    margin: 0;
    line-height: normal;
}

.agb-consent-checkbox {
    cursor: pointer;
    height: 25px;
    width: 25px;
}

@media screen and (max-width: 1440px) {
    .infoGrid {
        margin: 0 auto;
        gap: 20px;
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .inputGroup {
        width: 100%;
    }

    .contactPage h1 {
        font-size: 32px;
    }

    .infoCard img {
        width: 25px;
        height: 25px;
    }
}

@media screen and (max-width: 900px) {
    .infoGrid {
        margin: 0 auto;
        gap: 20px;
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .inputGroup {
        width: 100%;
    }

    .contactPage h1 {
        font-size: 32px;
    }

    .infoCard img {
        width: 25px;
        height: 25px;
    }
}

@media screen and (max-width: 650px) {
    .infoGrid {
        margin: 0 auto;
        gap: 20px;
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .inputGroup {
        width: 100%;
    }

    .contactPage h1 {
        font-size: 32px;
    }

    .infoCard img {
        width: 25px;
        height: 25px;
    }

    input[type="text"],
    input[type="email"],
    input[type="tel"],
    textarea {
        width: 100%;
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 8px;
        box-sizing: border-box;
        font-size: 1rem;
    }

    button:disabled,
    button[disabled] {
        border: 1px solid #999999;
        background-color: #cccccc;
        color: #666666;
    }

    .contactInfo a {
        padding-left: 15px;
        padding-right: 15px;
        font-size: 24px;
        margin-top: 18px;
        margin-bottom: 18px;
        text-decoration: none;
        color: #0056b3;
    }

    .contactInfo a:hover {
        color: #0056b3;
        text-decoration: underline;
    }
}