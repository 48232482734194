.imp-container {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.impressum-page-holder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.impressum-content-container {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(1, auto);
  align-items: baseline;
  justify-content: baseline;
}

p {
  font-size: 1rem;
  font-weight: 500;
  white-space: 100vw;
}

h3 {
  font-size: 1.5rem;
  font-weight: 500;
}

.page-heading h1 {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 25px;
}

.register-content-holder {
  margin-top: 25px;
}

.lead-holder {
  margin-top: 25px;
}

.content-response-holder {
  margin-top: 25px;
}

.content-origin-holder {
  margin-top: 25px;
}

.powered-by-holder {
  margin-top: 25px;
}

.powered-by-link {
  color: #3a68ff;
  font-size: 1.2rem;
  font-weight: 500;
  text-decoration: none;
}

.powered-by-link:hover {
  text-decoration: underline;
}
