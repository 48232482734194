/*
##############################

- Christian Bucher

LEGACY STYLING FOR SWIPER - REACT

cause idfk how to implement it with tailwind...

##############################
*/

.swiper-pagination {
	padding-top: 4px;
	padding-bottom: 4px;
	padding-left: 6px;
	padding-right: 6px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 16px;
}

.swiper-pagination-bullet {
	width: 10px;
	height: 10px;
	text-align: center;
	line-height: 20px;
	font-size: 12px;
	color: gray;
	opacity: 1;
	background: rgba(120, 120, 120, 1);
	border: 1px solid #121212;
}

.swiper-pagination-bullet-active {
	color: #fff;
	background: #fff;
}

.swiper-button-prev {
	@apply absolute m-0 p-0 top-0 left-0 h-[calc(100vh-80px)] w-1/6 bg-gradient-to-r from-black/25 to-black/0 hover:from-black/70 text-white;
}

.swiper-button-next {
	@apply absolute m-0 p-0 top-0 right-0 h-[calc(100vh-80px)] w-1/6 bg-gradient-to-l from-black/25 to-black/0 hover:from-black/70 text-white;
}