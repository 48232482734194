@media screen and (max-width: 770px) {
  .action-holder {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
  }
  .l-hero {
    padding: 24px 0px 0px 0px;
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  }
  100% {
    transform: translateY(0);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    opacity: 1; /* Endet vollständig sichtbar */
  }
}

.fly-in {
  opacity: 0;
  animation: 0.5s ease-out slideInFromBottom;
  animation-fill-mode: forwards; /* Behält den Endzustand der Animation bei */
}

.fly-in.delay-fly-1 {
  animation-delay: 0.1s; /* Delay für das erste Element */
}

.fly-in.delay-fly-2 {
  animation-delay: 0.2s; /* Delay für das zweite Element */
}

.fly-in.delay-fly-3 {
  animation-delay: 0.6s; /* Delay für das dritte Element */
}
