.g-nav {
    width: 100%;
    height: 85px;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
}

.g-nav__content-wrapper {
    height: 85px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    margin: 0 48px;
    max-width: 1280px;
    width: 100%;
}

.menu-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 11px;
    cursor: pointer;
    padding: 6px;
    display: none;
    visibility: hidden;
}

.gallery-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 11px;
    border: 1px solid;
    padding: 4px 12px;
    border-radius: 6px;
}

.gallery-btn:hover {
    color: #38387c;
    border: 1px solid #38387c;
    background-color: rgba(56, 56, 124, 0.2);
}

.menu-container {
    height: 30px;
    width: 30px;
    cursor: pointer;
    position: relative;
    top: calc(50% - 15px);
}

.search-wrapper-lg {
    width: 550px;
}

.menu-container .line {
    height: 25px;
    width: 25px;
    position: absolute;
    left: 4px;
    top: 4px;
    transition: transform 300ms ease-in-out;
}

.menu-container .line-inner {
    height: 2px;
    width: 24px;
    border-radius: 4px;
    position: absolute;
    transition: transform 200ms ease-in-out;
    transition-delay: 200ms;
}

.menu-container .line-inner-1 {
    top: 12px;
    transform: translateY(-4px);
}

.menu-container .line-inner-2 {
    bottom: 12px;
    transform: translateY(4px);
}

.menu-container.active .line-1 {
    transform: rotateZ(45deg);
}

.menu-container.active .line-2 {
    transform: rotateZ(-45deg);
}

.menu-container.active .line-inner {
    transform: none;
    transition: transform 200ms ease-in-out;
}

.menu-container.active .line {
    transition-delay: 200ms;
}

.menu-items-container {
    position: fixed;
    top: 85px;
    left: 0px;
    width: 300px;
    height: auto;
    clip-path: polygon(0 0, 100% 0, 100% 0%, 0 0%);
    transition: clip-path 0.2s;
    background-color: #fff;
}

.menu-items-container.active {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    background-color: #fff;
    transition: all 200ms ease;
}

.menu-items-container ul {
    list-style: none;
    background: transparent;
    padding: 0;
    margin: 0;
    width: auto;
    padding: 12px;
}

.menu-items-container ul li {
    padding: 0;
    margin: 0;
    width: auto;
    border-radius: 6px;
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: baseline;
    list-style: none;
    cursor: pointer;
    background: transparent;
    padding: 12px 24px;
}

.menu-items-container ul li:hover a {
    color: #fff;
}

.menu-items-container ul li:hover svg {
    stroke: #fff;
}

.menu-items-container ul li h3 {
    padding: 0;
    margin: 0;
}

.mc-li-1 {
    opacity: 0;
    transform: translateY(-25px);
    transition: opacity 0.1s, transform 0.1s;
    transition-delay: 0.1s;
}

.mc-li-2 {
    opacity: 0;
    transform: translateY(-25px);
    transition: opacity 0.15s, transform 0.15s;
    transition-delay: 0.15s;
}

.mc-li-3 {
    opacity: 0;
    transform: translateY(-25px);
    transition: opacity 0.2s, transform 0.2s;
    transition-delay: 0.2s;
}

.mc-li-1.active,
.mc-li-2.active,
.mc-li-3.active {
    opacity: 1;
    transform: translateY(0);
}

.search-wrapper-sm {
    display: none;
}

.nav-link:active {
    color: brown;
}

.horizNav-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 24px;
}

@media screen and (max-width: 1280px) {
    .search-wrapper-sm {
        display: block;
    }

    .menu-wrapper {
        display: flex;
        visibility: visible;
    }

    .horizMenu {
        display: none;
        visibility: hidden;
    }

    .menu-items-container {
        position: fixed;
        top: 85px;
        left: 0px;
        width: 100%;
        clip-path: polygon(0 0, 100% 0, 100% 0%, 0 0%);
        transition: clip-path 0.3s;
        background: #fff;
        transition-delay: 50ms;
    }

    .form-lg {
        display: none;
    }
}

@media screen and (max-width: 670px) {
    .logo-span {
        display: none;
        visibility: hidden;
    }

    .search-wrapper-sm {
        display: block;
    }

    .menu-wrapper {
        display: flex;
        visibility: visible;
    }

    .horizMenu {
        display: none;
        visibility: hidden;
    }

    .menu-items-container {
        position: fixed;
        top: 85px;
        left: 0px;
        width: 100%;
        clip-path: polygon(0 0, 100% 0, 100% 0%, 0 0%);
        transition: clip-path 0.3s;
        background: #fff;
        transition-delay: 50ms;
    }

    .form-lg {
        display: none;
    }
}